import Vue from "vue";
import VueI18n from 'vue-i18n'
import en from './en'
import ja from "./ja"
import locale from 'element-ui/lib/locale';
import jpLang from 'element-ui/lib/locale/lang/ja'; // 日语语言包
import enLang from 'element-ui/lib/locale/lang/en';
Vue.use(VueI18n);   // 全局注册国际化包

// 准备翻译的语言环境信息
const i18n = new VueI18n({
    locale: "jp",   // 初始化英文
    messages: {
        "en":{
            ...en,
            ...enLang
        },
        "jp": {
            ...ja,
            ...jpLang
        }
    }
})
locale.i18n((key, value) => i18n.t(key, value))

export default i18n