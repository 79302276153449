import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import i18n from './lang'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import '@/assets/js/rem'
import '@/assets/css/reset.css'
import '@/assets/css/common.css'
import '@/assets/css/m-menu.css'
import '@/assets/css/plugin.css'
import lottie from 'lottie-web';
Vue.prototype.$lottie = lottie;
// axios
import axios from 'axios'
Vue.prototype.$Axios = axios

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import { EventBus } from './assets/js/eventBus'
Vue.prototype.$EventBus = EventBus
Vue.use(mavonEditor);

router.afterEach(() => {
  let appSrcollTop = document.getElementById('app').scrollTop
  if (appSrcollTop !== 0) {
    document.getElementById('app').scrollTop = 0
    return
  }
  let docSrcollTop = document.documentElement.scrollTop
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App),
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')
