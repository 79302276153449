<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default{
  name: 'app',
  mounted () {
    window.addEventListener('beforeunload', () => {
      window.localStorage.removeItem('localeLang')
    })
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', () => {
      window.localStorage.clear()
    })
  }
}
</script>

<style lang="less">
#app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
</style>
