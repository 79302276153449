export default {
    contactBtn: 'Contact Us',
    singIn: 'Sign In',
    header: [
        {
            name: 'Products',
            url: '/product',
            list:[
                {
                    name: 'Speech-to-Text',
                    items: [
                        {
                            imgUrl: 'real_time.png',
                            name: 'Real-Time Transcription',
                            content: 'Awesome real-time performance and high accuracy.',
                            url: '/product/real-time-transcription'
                        },
                        // {
                        //     imgUrl: 'upload_g.png',
                        //     name: 'Audio/Video Transcription<br/>(Standard Edition)',
                        //     content: 'Convert audio/video to text in transcript and subtitle formats.',
                        //     url: '/product/tse'
                        // },
                        {
                            imgUrl: 'upload_e.png',
                            name: 'Audio/Video Transcription',
                            content: 'A 30-minute audio/video file can be transcribed within 30 seconds.',
                            url: '/product/tese'
                        },
                        {
                            imgUrl: 'short.png',
                            name: 'Short Speech Transcription',
                            content: 'Transcription of audio less than 60 seconds, suitable for speech interaction applications.',
                            url: '/product/short-speech-transcription'
                        },
                        {
                            imgUrl: 'languages.png',
                            name: 'Languages',
                            content: 'Support for dozens of global mainstream languages, with a recognition accuracy rate of over 95%.',
                            url: '/product/languages'
                        }
                    ]
                },
                {
                    name: 'Speech Extensions',
                    items: [
                        {
                            imgUrl: 'language_identify.png',
                            name: 'Language Identification (Beta)',
                            content: 'A powerful tool that helps you quickly identify languages.',
                            url: '1-6',
                            disabled: true
                        },
                        {
                            imgUrl: 'keywords_spotting.png',
                            name: 'Keyword Spotting (Beta)',
                            content: 'Sensitive and convenient, always ready to respond, optimizing user experience.',
                            url: '1-7',
                            disabled: true
                        },
                        {
                            imgUrl: 'speaker_recognition.png',
                            name: 'Speaker Recognition (Beta)',
                            content: 'Unique voiceprint verification, safeguarding your system.',
                            url: '1-8',
                            disabled: true
                        }
                    ]
                },
                // {
                //     name: 'Translation',
                //     items: [
                //         {
                //             imgUrl: 'translation.png',
                //             name: 'Translation',
                //             content: 'Support multi-language translation, applicable to translators, simultaneous interpretation.',
                //             url: '/product/translation'
                //         }
                //     ]
                // },
                {
                    name: 'Deployments',
                    items: [
                        {
                            imgUrl: 'prem.png',
                            name: 'On-Prem',
                            content: 'Support on-prem deployment for higher security and privacy.',
                            url: '/product/on-prem'
                        },
                        {
                            imgUrl: 'device.png',
                            name: 'On-Device',
                            content: 'Convert speech to text on devices, with low memory usage, compact models, and multilingual support.',
                            url: '/product/on-device'
                        },
                        {
                            imgUrl: 'API.png',
                            name: 'Cloud API',
                            content: 'Learn about the benefits of our API and how it works.',
                            url: '/product/cloud-api'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Features',
            url: '/features',
            list: [
                {
                    name: '',
                    mName: 'Features',
                    items: [
                        {
                            imgUrl: 'multi_languages_recognition.png',
                            name: 'Multi-Language Support',
                            content: 'Seamless communication, embracing diverse cultures!',
                            url: '/features?url=multi-language-support'
                        },
                        {
                            imgUrl: 'code_switching.png',
                            name: 'Code-Switching',
                            content: 'Effortlessly handling multilingual scenarios, smooth and natural!',
                            url: '/features?url=code-switching'
                        },
                        {
                            imgUrl: 'rescource_saving.png',
                            name: 'Resource Saving',
                            content: 'Highly efficient algorithms, deployable with CPUs, no need for expensive GPUs.',
                            url: '/features?url=resource-saving'
                        },
                        {
                            imgUrl: 'captioning.png',
                            name: 'Real-Time Subtitles',
                            content: 'Real-time subtitles in speech, meeting diverse needs!',
                            url: '/features?url=real-time-subtitles'
                        },
                        {
                            imgUrl: 'characteristic_features.png',
                            name: 'Advanced Features',
                            content: 'Enhanced functions or services provided by speech recognition systems to adapt to different application scenarios.',
                            url: '/features?url=advanced-features'
                        },
                        {
                            imgUrl: 'full_link.png',
                            name: 'Full-Link',
                            content: 'From speech signal processing, speech recognition to speech synthesis, a one-stop professional speech interaction technology solution!',
                            url: '/features?url=full-link'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Pricing',
            url: '/pricing'
        },
        {
            name: 'Resources',
            url: '/documentation',
            list:[
                {
                    name: 'Documentation',
                    items: [
                        {
                            imgUrl: 'API.png',
                            name: 'Cloud API',
                            content: 'Learn about the benefits of our API and how it works.',
                            url: '/documentation?url=api/intro/notice.md'
                        },
                        {
                            imgUrl: 'device.png',
                            name: 'On-Device',
                            content: 'Convert speech to text on devices, with low memory usage, compact models, and multilingual support.',
                            url: '/documentation?url=on-device/asr/android.md'
                        },
                        {
                            imgUrl: 'prem_resource.png',
                            name: 'On-Prem (Eng. Edition)',
                            content: 'View the product overview, deployment manual, and API protocol to help you quickly integrate the On-prem products.',
                            url: '/documentation?url=on-prem/intro/overview.md'
                        }
                    ]
                },
                {
                    name: 'About Us',
                    items: [
                        {
                            imgUrl: 'about.png',
                            name: 'About Dolphin AI',
                            content: 'We are committed to providing you with the highest quality technology and service.',
                            url: '/about'
                        },
                        {
                            imgUrl: 'security.png',
                            name: 'Security Certification',
                            content: "To ensure that customers can use DolphinVoice with confidence and assurance.",
                            url: '/security'
                        }
                        // {
                        //     imgUrl: 'help.png',
                        //     name: 'Help Center',
                        //     content: 'If you have any questions, you can get answers here',
                        //     url: '3-6'
                        // }
                    ]
                },
                // {
                //     name: 'Download',
                //     items: [
                //         {
                //             imgUrl: 'sdk.png',
                //             name: 'Download',
                //             content: '',
                //             url: '4'
                //         }
                //     ]
                // }
            ]
        },
        {
            name: 'Media',
            url: '/blog'
        }
    ],
    footer: {
        banner: {
            tip1: 'Ready to get started?',
            tip2: "The world's best choice for AI speech technology services.",
            btnText: 'Contact Us'
        },
        menus: {
            row1: [
                {
                    name: 'E-mail',
                    email: 'voice.contact@dolphin-ai.jp'
                },
                {
                    name: 'Address',
                    addr: '170-0013 Hareza Tower 20F,<br/>1-18-1 Higashiikebukuro,<br/>Toshima-ku, Tokyo'
                }
            ],
            row2: {
                name: 'Products',
                list1: [
                    {
                        name: 'Real-Time Transcription',
                        url: '/product/real-time-transcription'
                    },
                    // {
                    //     name: 'Audio/Video Transcription (Standard Edition)',
                    //     url: '/product/tse'
                    // },
                    {
                        name: 'Audio/Video Transcription',
                        url: '/product/tese'
                    },
                    {
                        name: 'Short Speech Transcription',
                        url: '/product/short-speech-transcription'
                    }
                ],
                list2: [
                    {
                        name: 'Languages',
                        url: '/product/languages'
                    },
                    // {
                    //     name: 'Translation',
                    //     url: '/product/translation'
                    // },
                    {
                        name: 'Language Identification (Beta)',
                        url: 'disabled'
                    },
                    {
                        name: 'Keyword Spotting (Beta) ',
                        url: 'disabled'
                    },
                    {
                        name: 'Speaker Recognition (Beta)',
                        url: 'disabled'
                    }
                ],
                list3: [
                    {
                        name: 'On-Prem',
                        url: '/product/on-prem'
                    },
                    {
                        name: 'On-Device',
                        url: '/product/on-device'
                    },
                    {
                        name: 'Cloud API',
                        url: '/product/cloud-api'
                    }
                ]
            },
            row3: {
                name: 'Features',
                list: [
                    {
                        name: 'Multi-Language Support',
                        url: '/features?url=multi-language-support'
                    },
                    {
                        name: 'Code-Switching',
                        url: '/features?url=code-switching'
                    },
                    {
                        name: 'Resource Saving',
                        url: '/features?url=resource-saving'
                    },
                    {
                        name: 'Real-Time Subtitles',
                        url: '/features?url=real-time-subtitles'
                    },
                    {
                        name: 'Advanced Features',
                        url: '/features?url=advanced-features'
                    },
                    {
                        name: 'Full-Link',
                        url: '/features?url=full-link'
                    }
                ]
            },
            row4: {
                name: 'Resources',
                list1: [
                    {
                        name: 'Cloud API',
                        url: 'api/intro/notice.md'
                    },
                    {
                        name: 'On-Device',
                        url: 'on-device/asr/android.md'
                    },
                    {
                        name: 'On-Prem (Eng. Edition)',
                        url: 'on-prem/intro/overview.md'
                    }
                ],
                list2: [
                    // {
                    //     name: 'Download',
                    //     url: ''
                    // }
                ]
            },
            row5: {
                name: 'Company',
                list1: [
                    {
                        name: 'About Us',
                        url: '/about'
                    },
                    {
                        name: 'Contact Us',
                        url: '/contact-us'
                    },
                    {
                        name: 'Help Center',
                        url: 'api/intro/notice.md'
                    }
                ],
                list2: [
                    {
                        name: 'Privacy Policy',
                        url: 'common/privacy'
                    },
                    {
                        name: 'Terms of Service',
                        url: 'common/tos'
                    },
                    {
                        name: 'Disclosure based on the SCTA',
                        url: 'common/law'
                    }
                ]
            }
        },
        cpr: 'Copyright © 2024 Dolphin AI, K.K.'
    },
    more: 'Learn more',
    soon: 'Coming soon',
    free_btn: 'Try Free',
    home: {
        exp: {
            text1: 'Expand your business faster and at a lower cost by choosing our speech transcription API.',
            btn1: 'Learn More',
            text2: 'Leading ',
            text_blue: 'Intelligent Speech ',
            text3: 'Technology: The Foundation of Enhanced User Experience',
            text4: 'Relying on industry-leading LLM and vast data, we offer a rich set of speech recognition capabilities that can be quickly integrated through APIs.',
            btn2: 'Get Started Free',
            tabs_name1: 'Real-Time Transcription',
            tabs_name2: 'Audio/Video Transcription',
            asrTip: 'Click on micphone to start trying',
            asrStart: 'Start',
            asrStop: 'Stop',
            tip: 'For demo purposes only. Please sign up to experience all features (with 10 hours free per month).',
            time: 'Timer',
            reset: 'Start Over',
            resetBtn: 'Transcribe More',
            fileTip: 'Click or drop files here to upload',
            format: 'File Formats Supported: WAV, PCM, OPUS, MP3, AMR, AAC',
            fileSize: 'Maximum File Size: 50M',
            fileBtn: 'Transcribe',
            downloadType: [
                {
                    label: 'Download as text',
                    value: 'text'
                },
                {
                    label: 'Download as srt',
                    value: 'srt'
                }
            ],
            fileList: {
                title: 'File:',
                status1: 'has been added',
                status2: 'Uploading',
                status3: 'Transcribing'
            }
        },
        speech: {
            h2: 'Rich Speech Recognition APIs & SDKs',
            h3: 'Based on the industry-leading AI LLM, our speech recognition technology guarantees high quality service.',
            info: 'Beyond speech recognition accuracy, we also offer a variety of practical features. You can experience and conduct comparative tests online to find the most ideal solution.',
            btn: 'Contact Us',
            contrastImg: 'contrast_en.png'
        },
        offers: {
            h2: 'Dolphin Voice Offers You the Optimal Choice',
            h3: 'Empower your business with our globally leading speech recognition technology. It safeguards speech interaction with an efficient and accurate core engine, and now supports more than 20 languages.',
            list: [
                {
                    title: 'Higher Accuracy',
                    name: 'higher_accuracy',
                    mIcon: 'higher_accuracy',
                    info: "Dolphin Voice's speech recognition technology can accurately transcribe every sentence of speech. Based on powerful algorithms, our engines can minimize error rates, provide reliable results and help you make critical business decisions."
                },
                {
                    title: 'Lower Price',
                    name: 'lower_price',
                    mIcon: 'lower_price',
                    info: 'Ensure high quality and reduce operational costs with Dolphin Voice. Our cost-effective solutions are designed to deliver top-tier speech recognition technology at a fraction of the price. Allow you to maximize your ROI while enjoying the benefits of advanced speech technology.'
                },
                {
                    title: 'Professional, Efficient, and Stable',
                    name: 'professional_stable',
                    mIcon: 'professional_stable',
                    info: 'Dolphin Voice, certified by the ISO 27001 Information Security Management System, is designed for continuous 24/7 operations. Our system can withstand high-load usage of some scenarios and satisfy your business needs.'
                },
                {
                    title: 'International Support',
                    name: 'international_support',
                    mIcon: 'international_support',
                    info: "Expand your global reach with Dolphin Voice's multilingual capabilities. Supporting over 20 languages, our engine helps your business go overseas and operate in global markets smoothly."
                }
            ]
        },
        support: {
            h2: 'Multi-Language Support for International Needs',
            h3: 'Multilingual speech recognition and translation, real-time transcription, flexible deployment, meeting the international demands of our customers.',
            languages: [
                {
                    name: 'Japanese',
                    c_flag: 'Japan'
                },
                {
                    name: 'English',
                    c_flag: 'USA'
                },
                {
                    name: 'Mandarin',
                    c_flag: 'China'
                },
                {
                    name: 'Korean',
                    c_flag: 'Korea'
                },
                {
                    name: 'Russian',
                    c_flag: 'Russia'
                },
                {
                    name: 'Thai',
                    c_flag: 'Thailand'
                },
                {
                    name: 'Vietnamese',
                    c_flag: 'Vietnam'
                },
                {
                    name: 'Hindi',
                    c_flag: 'India'
                },
                {
                    name: 'Arabic',
                    c_flag: 'Arab'
                },
                {
                    name: 'Burmese',
                    c_flag: 'Myanmar'
                },
                {
                    name: 'Indonesian',
                    c_flag: 'Indonesia'
                },
                {
                    name: 'Malay',
                    c_flag: 'Malaysia'
                },
                {
                    name: 'Spanish',
                    c_flag: 'Spain'
                },
                {
                    name: 'German',
                    c_flag: 'Germany'
                },
                {
                    name: 'French',
                    c_flag: 'France'
                },
                {
                    name: 'Italian',
                    c_flag: 'Italy'
                },
                {
                    name: 'Polish',
                    c_flag: 'Poland'
                },
                {
                    name: 'Dutch',
                    c_flag: 'Netherlands'
                },
                {
                    name: 'Portuguese',
                    c_flag: 'Portugal'
                },
                {
                    name: 'Kazakh',
                    c_flag: 'Kazakhstan'
                },
                {
                    name: 'Mongolian',
                    c_flag: 'Mongolia'
                }
            ],
            btn: 'All Languages......'
        },
        scenarios: {
            h2: 'Speech-to-Text for Multiple Scenarios',
            h3: 'We offer speech recognition solutions that support flexible deployment options such as on premises, cloud API, and on devices to meet the needs of different scenarios.',
            list: [
                {
                    h5: 'Short Speech Transcription',
                    h6: 'Transcription of audio less than 60 seconds, fast delivery of the transcribed text.',
                    url: '/product/short-speech-transcription'
                },
                {
                    h5: 'Real-Time Transcription',
                    h6: 'Awesome real-time performance and high accuracy.',
                    url: '/product/real-time-transcription'
                },
                {
                    h5: 'Audio/Video Transcription',
                    h6: 'Support upload of audio and video files and fast transcription.',
                    url: '/product/tese'
                },
                {
                    h5: 'On-Device Transcription',
                    h6: 'Convert speech to text on devices, with low memory usage, compact models, and multilingual support.',
                    url: '/product/on-device'
                }
            ]
        },
        products: {
            h2: 'Multi-Form Products, Flexible Integration',
            h3: 'Dolphin Voice supports flexible deployment to better meet the security and privacy needs of different customers.',
            list: [
                {
                    h5: 'Cloud API',
                    h6: 'Sign up for immediate access to our speech-to-text API and we provide a wealth of code examples and documentation.',
                    url: 'api/intro/notice.md',
                    mImg: 'API.svg'
                },
                {
                    h5: 'On-Device',
                    h6: 'Our speech recognition engines can be deployed on various devices (iOS, Android, etc.), allowing for speech recognition without the internet connection.',
                    url: 'on-device/asr/android.md',
                    mImg: 'SDK.svg'
                },
                {
                    h5: 'On-Prem',
                    h6: "Deploy the speech-to-text engines on the clients' own server, supporting both cloud hosting and on-prem deployment.",
                    url: '/product/on-prem',
                    mImg: 'Prem.svg'
                }
            ]
        },
        individual: {
            h2: 'Practical Features to Meet Individualized Needs',
            h3: 'What our clients desire is our mission. The essence of technology is to create value for clients.',
            list1: [
                {
                    bg: 'asr',
                    info1: 'Multi-Language Support',
                    info2: 'Seamless communication, embracing diverse cultures!'
                },
                {
                    bg: 'code',
                    info1: 'Code-Switching',
                    info2: 'Seamless code-switching capabilities, smooth and natural speech recognition.'
                },
                {
                    bg: 'resource',
                    info1: 'Resource Saving',
                    info2: 'Highly efficient algorithms, deployable with CPUs, no need for expensive GPUs.'
                },
                {
                    bg: 'captioning',
                    info1: 'Real-time Subtitles',
                    info2: 'Real-time subtitles in speech, meeting diverse needs!'
                },
                {
                    bg: 'characteristic',
                    info1: 'Features',
                    info2: 'Enhanced functions or services provided by speech recognition systems to adapt to different application scenarios.'
                },
                {
                    bg: 'full_link',
                    info1: 'Full-Link',
                    info2: 'Full coverage from acoustic model to language model, a one-stop professional speech interaction technology solution!'
                }
            ],
            ask: {
                title: 'FAQ'
            },
            list2:[
                {
                    q: 'What languages are supported by speech recognition?',
                    a: 'Currently, we support Japanese, English, Chinese, Japanese-English mixed, and Chinese-English mixed.'
                },
                {
                    q: 'What audio formats are supported by speech recognition?',
                    a: 'We support PCM, WAV, MP3, Opus, and more.'
                },
                {
                    q: "What programming languages can be supported?",
                    a: 'We support WebSocket, Python, C, Android, iOS, H5/JS.'
                },
                {
                    q: 'What sampling rates and bit depths are supported?',
                    a: 'Currently, we only support a sampling rate of 16000 Hz with a 16-bit audio bit depth.'
                },
                {
                    q: 'How quickly can you expect the recognition results?',
                    a: 'Response time < 500ms.'
                },
                {
                    q: 'Does it support offline speech recognition?',
                    a: 'Yes, offline speech recognition is currently supported on Android and iOS platforms.'
                }
            ]
        },
        news: {
            h2: 'News',
            btn: 'More'
        }
    },
    pscTitle: 'Application Scenarios',
    sst: {
        p1: {
            title: 'Short Speech Transcription',
            text: 'Transcription of audio less than 60 seconds, suitable for speech interaction applications.',
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        h4: 'Our short speech transcription technology can meet your needs across various scenarios.',
        pscLists: [
            {
                title: 'Voice Typing',
                imgUrl: 'scenarios_1.jpg',
                content: 'Make typing-free operation possible in diverse scenarios like car navigation and chat apps, improving communication efficiency.'
            },
            {
                title: 'Voice Commands',
                imgUrl: 'scenarios_2.jpg',
                content: 'Match voice commands in the command list for smart home, intelligent wearables, etc.'
            },
            {
                title: 'Human-Computer Interaction',
                imgUrl: 'scenarios_3.jpg',
                content: 'Integrated into various dialog systems such as robots and self-service devices to meet the demand for natural human-computer interaction.'
            }
        ],
        advantage: {
            h2: 'Technical Advantages',
            h4: 'Our cutting-edge technology offers a significant advantage to your products, empowering you to lead over your competitors.',
            prompt: {
                ptitle: 'Instant Response',
                pinfo1: 'Fast delivery of the transcribed text to meet requirements for low latency.',
                pinfo2: 'Based on advanced algorithms and efficient processing frameworks, we ensure instant and accurate speech recognition, which is critical for real-time applications, such as instant messaging and online gaming. Our services significantly reduce latency, affording users the pleasure of swift and smooth interactions.',
                contrastImg: 'sst_contrast_en.png'
            },
            multiLangs: {
                ptitle: 'Multi-Language Support',
                pinfo: 'With support for over 20 languages like Japanese, English, and Mandarin, our speech recognition technology covers multi-language interaction scenarios such as smart home and intelligent car devices. With our technology, users can enjoy convenient and natural speech interactions anywhere. They can control home devices with a simple voice command to their smart speaker or set route and get information without lifting a finger while on the road. Our powerful multilingual models empower your product to meet the needs of users from different countries and regions, extending your service to a global scale.'
            },
            languagesList: [
                {
                    name: 'Japanese',
                    c_flag: 'Japan'
                },
                {
                    name: 'English',
                    c_flag: 'USA'
                },
                {
                    name: 'Mandarin',
                    c_flag: 'China'
                },
                {
                    name: 'Korean',
                    c_flag: 'Korea'
                },
                {
                    name: 'Russian',
                    c_flag: 'Russia'
                },
                {
                    name: 'Thai',
                    c_flag: 'Thailand'
                },
                {
                    name: 'Vietnamese',
                    c_flag: 'Vietnam'
                },
                {
                    name: 'Hindi',
                    c_flag: 'India'
                },
                {
                    name: 'Arabic',
                    c_flag: 'Arab'
                },
                {
                    name: 'Burmese',
                    c_flag: 'Myanmar'
                },
                {
                    name: 'Indonesian',
                    c_flag: 'Indonesia'
                },
                {
                    name: 'Malay',
                    c_flag: 'Malaysia'
                },
                {
                    name: 'Spanish',
                    c_flag: 'Spain'
                },
                {
                    name: 'German',
                    c_flag: 'Germany'
                },
                {
                    name: 'French',
                    c_flag: 'France'
                },
                {
                    name: 'Italian',
                    c_flag: 'Italy'
                },
                {
                    name: 'Polish',
                    c_flag: 'Poland'
                },
                {
                    name: 'Dutch',
                    c_flag: 'Netherlands'
                },
                {
                    name: 'Portuguese',
                    c_flag: 'Portugal'
                },
                {
                    name: 'Kazakh',
                    c_flag: 'Kazakhstan'
                },
                {
                    name: 'Mongolian',
                    c_flag: 'Mongolia'
                }
            ],
            btn: 'All Languages......',
            techLeadership: {
                ptitle: 'Advanced Technology',
                pinfo1: 'Based on massive audio and text datasets, we have developed an unparalleled end-to-end speech recognition system with an exceptional accuracy of up to 98%.',
                pinfo2: 'Based on leading AI technology, massive training datasets and advanced speech recognition frameworks, we have developed highly accurate speech recognition models. It achieves high accuracy even in challenging scenarios, making it ideal for diverse scenarios, such as meeting minutes and medical documentation.'
            },
            puncPrediction: {
                ptitle: 'Automatic Punctuation Prediction',
                pinfo1: 'On the basis of neural networks and large-scale text datasets, we support automatic prediction of punctuation and sentence segmentation.',
                pinfo2: 'By utilizing neural networks for in-depth analysis of massive text datasets, we support automatic prediction of punctuation and sentence segmentation, thus improving the clarity and natural flow of the transcribed text for easy understanding.'
            }
        }
    },
    rtt: {
        p1: {
            title: 'Real-Time Transcription',
            text: 'Support real-time streaming audio transcription with instant transcript return, provide quick and accurate transcription of your extended speeches, revolutionize how you communicate with advanced speech recognition technology.',
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        pss: {
            h2: 'Application Scenarios',
            h4: 'Efficient and accurate real-time transcription with a wide range of applications to fully meet customer needs.',
            scenarios: [
                {
                    title: 'Meeting Minutes',
                    info: 'Transcribe meeting content in real-time into text, convenient for participants to take notes and review key points.',
                    icon: 'service_1.svg'
                },
                {
                    title: 'News Interview',
                    info: 'A swift and convenient speech-to-text solution to meet needs in a variety of scenarios.',
                    icon: 'service_2.svg'
                },
                {
                    title: 'Customer Service Call Center',
                    info: 'Real-time transcription of customer service calls, facilitating the recording and analysis of customer needs, enhancing the quality of service.',
                    icon: 'service_3.svg'
                },
                {
                    title: 'Medical Documentation',
                    info: 'Real-time speech recognition improves the efficiency of medical document writing and reduces the clerical work for medical staff.',
                    icon: 'service_4.svg'
                },
                {
                    title: 'Court Transcript',
                    info: 'Real-time transcription during court proceedings, ensuring the accuracy and completeness of court records.',
                    icon: 'service_5.svg'
                },
                {
                    title: 'Education and Training',
                    info: 'Real-time subtitles help students better understand and absorb course content, and enhance learning efficiency.',
                    icon: 'service_6.svg'
                },
                {
                    title: 'Simultaneous Interpretation',
                    info: 'Real-time speech transcription and translation, achieving cross-linguistic communication.',
                    icon: 'service_7.svg'
                },
                {
                    title: 'Real-Time Subtitles',
                    info: 'Provide real-time subtitles for live events, lectures, etc., allowing the audience to understand the content instantly.',
                    icon: 'service_8.svg'
                },
                {
                    title: 'Human-Computer Interaction',
                    info: "Make typing-free operation possible in diverse scenarios like car navigation and chat apps.",
                    icon: 'service_9.svg'
                }
            ]
        },
        features: {
            h2: 'High Accuracy',
            h4: 'Dolphin Voice provides efficient and accurate speech recognition technology.'
        },
        services: {
            h2: 'Full-Platform Compatibility, Empowering Online Meeting Services',
            h4: 'Anytime-and-anywhere access to services, flexibly applied to any scenario, meeting all your needs.',
            lists: [
                {
                    icon: 'API.svg',
                    title: 'Cloud API',
                    info: 'Sign up for immediate access to our speech-to-text API and we provide a wealth of code examples and documentation.'
                },
                {
                    icon: 'SDK.svg',
                    title: 'On-Device',
                    info: 'Our speech recognition engines can be deployed on various devices (iOS, Android, etc.), allowing for speech recognition without the internet connection.'
                },
                {
                    icon: 'Prem.svg',
                    title: 'On-Prem',
                    info: "Deploy the speech-to-text engines on the clients' own server, supporting both cloud hosting and on-prem deployment."
                }
            ]
        },
        support: {
            h2: 'Multi-Language Support',
            h4: 'Our multilingual capabilities ensure the precise transcription of your audio and video files.',
            lists: [
                {
                    icon: 'languages_bg.svg',
                    title: 'Multiple Languages',
                    info: 'Regardless of the language used, we can accurately transcribe your meeting content.'
                },
                {
                    icon: 'translation_bg.svg',
                    title: 'Translation',
                    info: 'Accurate transcription in multiple languages, intelligent translation without linguistic barriers.'
                },
                {
                    icon: 'subtitles_bg.svg',
                    title: 'Subtitles',
                    info: 'Rapid subtitle generation powered by our precise speech transcription.'
                },
                {
                    icon: 'code_bg.svg',
                    title: 'Code-Switching',
                    info: 'Seamless code-switching capabilities, smooth and natural speech recognition.'
                }
            ]
        },
        choose: {
            h2: 'Why Choose Us?',
            h4: 'We provide cutting-edge technology, exceptional service value, and the most competitive pricing.',
            list: [
                {
                    icon: 'choose_icon_1.svg',
                    title: 'Speaker Diarization',
                    info: 'Automatically distinguish speakers in the audio for efficient analysis of multi-party conversations.'
                },
                {
                    icon: 'choose_icon_2.svg',
                    title: 'Faster Transcription',
                    info: 'Achieve rapid speech recognition with our efficient services.'
                },
                {
                    icon: 'choose_icon_3.svg',
                    title: 'Lower Cost',
                    info: 'Minimize your costs by using our services.'
                },
                {
                    icon: 'choose_icon_4.svg',
                    title: 'Multi-Language Support',
                    info: 'Embrace global communication with multi-language models and code-switching support.'
                },
                {
                    icon: 'choose_icon_5.svg',
                    title: 'Higher Accuracy',
                    info: 'Based on advanced speech recognition technology, achieve accurate speech transcription. '
                },
                {
                    icon: 'choose_icon_6.svg',
                    title: 'Flexible Deployment',
                    info: 'Choose from cloud API, on-prem, and on-device deployment options to meet your needs.'
                }
            ]
        }
    },
    file: {
        p1: {
            title: 'Audio/Video Transcription for Boosted Productivity',
            text: 'Our ASR model is capable of transcribing audio/video files in various formats into text, with support for two output formats: transcripts and subtitles.',
            infos: [
                {
                    left: 'Accuracy: ',
                    right: 'Peaking at 98%.'
                },
                {
                    left: 'Speed: ',
                    right: 'Lightning-fast, transcribing a 30-minute audio/video file with 30 seconds.'
                },
                {
                    left: 'Cost: ',
                    right: 'Compared to other solutions, up to 80% savings.'
                }
            ],
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        speaker: 'role',
        scenarios: {
            h2: 'Application Scenarios',
            h4: 'Utilize our audio transcription service to increase your business efficiency and reduce costs.',
            list: [
                {
                    icon: 'scenarios_1.svg',
                    info1: 'Media and Entertainment',
                    info2: 'Quickly add subtitles to your videos, making it easy for users to access and search for videos, as well as improving the efficiency of video editing.'
                },
                {
                    icon: 'scenarios_2.svg',
                    info1: 'Law and Compliance',
                    info2: 'Our AI speech recognition solutions facilitate call analysis, risk analysis, and court record.'
                },
                {
                    icon: 'scenarios_3.svg',
                    info1: 'Education and Training',
                    info2: 'Generate reference texts for recorded lectures, webinars, and other events to enhance search efficiency.'
                },
                {
                    icon: 'scenarios_4.svg',
                    info1: 'Customer Service Call Center',
                    info2: 'Monitor and analyze service quality to enhance the customer experience and streamline operational costs.'
                }
            ],
            scene: [
                {
                    icon: 'scene_1.svg',
                    info: 'Interview Transcription'
                },
                {
                    icon: 'scene_2.svg',
                    info: 'Medical Transcription'
                },
                {
                    icon: 'scene_3.svg',
                    info: 'Conference Call Analysis'
                },
                {
                    icon: 'scene_4.svg',
                    info: 'Transcription of Podcasts'
                },
                {
                    icon: 'scene_5.svg',
                    info: 'Video to Text'
                },
                {
                    icon: 'scene_6.svg',
                    info: 'Audio to Text'
                },
                {
                    icon: 'scene_7.svg',
                    info: 'Subtitle Generation'
                },
                {
                    icon: 'scene_8.svg',
                    info: 'Speech Recognition'
                }
            ]
        },
        models: {
            h2: 'Speech Recognition Models',
            h4: 'Choose the model that suits your specific needs.',
            list: [
                {
                    icon: 'models_1.svg',
                    name: 'Extreme Speed Edition',
                    info1: 'Most valuable speech-to-text model',
                    info2: "We offer the most efficient audio transcription services, doubling your productivity with an accuracy rate of up to 98%, all at only 30% of the cost of our competitors. Our solution stands out for its technical excellence and cost-effectiveness."
                },
                {
                    icon: 'models_2.svg',
                    name: 'Standard Edition',
                    info1: 'Lower cost options',
                    info2: 'Our models, tailored through specialized training, push accuracy to 98%, excelling in the transcription of industry-specific terminology. Our high-concurrency services adeptly satisfy the increased demands of expanding enterprises, while presenting an economical and accessible option.'
                }
            ]
        },
        offer: {
            h2: 'What Can We Offer You?',
            h4: 'Utilize our advanced technology to achieve lightning-fast transcription of audio and video files.',
            list: [
                {
                    icon: 'offer_1.svg',
                    title: 'Speech Recognition',
                    info: 'With our powerful technology, you can enjoy the quick transcription of speech to text.'
                },
                {
                    icon: 'offer_2.svg',
                    title: 'Multi-Language Support',
                    info: 'Our speech recognition models support over 20 languages and accommodates various accents.'
                },
                {
                    icon: 'offer_3.svg',
                    title: 'Speaker Diarization',
                    info: 'With this service, the speaking roles in multi-party conversations can be detected.'
                },
                {
                    icon: 'offer_4.svg',
                    title: 'Field-Specific Model',
                    info: 'Our models, fine-tuned for specific fields, promise higher recognition accuracy.'
                },
                {
                    icon: 'offer_5.svg',
                    title: 'Audio & Video Structured Extraction',
                    info: 'Our audio transcription technology facilitates user access to audio content by text-based searches.'
                },
                {
                    icon: 'offer_6.svg',
                    title: 'Automatic Punctuation Prediction',
                    info: 'Integrate punctuation—commas, periods, question marks, and exclamation marks—during audio/video transcription.'
                },
                {
                    icon: 'offer_7.svg',
                    title: 'Custom Vocabulary',
                    info: 'Support customization of hot words—such as personal and place names, and industry-specific terminology to enhance the accuracy.'
                },
                {
                    icon: 'offer_8.svg',
                    title: 'Transcription Formats',
                    info: 'Transcribe the audio/video file into the format of your choice (text or subtitles).'
                }
            ]
        },
        development: {
            h2: 'Flexible Deployment',
            h4: 'Support multi-platform deployment to meet the diverse needs of our customers.',
            lists: [
                {
                    icon: 'API.svg',
                    title: 'Cloud API',
                    info: 'Sign up for immediate access to our speech-to-text API and we provide a wealth of code examples and documentation.'
                },
                {
                    icon: 'Prem.svg',
                    title: 'On-Prem',
                    info: "Deploy the speech recognition engines on the customer's own servers, supporting cloud hosting or on-premise deployment."
                }
            ]
        },
        support: {
            h2: 'Multi-Language Support',
            h4: 'Our multilingual support guarantees precise transcription for your audio and video files.',
            lists: [
                {
                    icon: 'languages_bg.svg',
                    title: 'Multiple Languages',
                    info: 'Regardless of the language used, we can accurately transcribe your file content.'
                },
                {
                    icon: 'translation_bg.svg',
                    title: 'Translation',
                    info: 'Accurate transcription in multiple languages, intelligent translation without linguistic barriers.'
                },
                {
                    icon: 'subtitles_bg.svg',
                    title: 'Subtitles',
                    info: 'Rapid subtitle generation powered by our precise speech transcription.'
                },
                {
                    icon: 'code_bg.svg',
                    title: 'Code-Switching',
                    info: 'Seamless code-switching capabilities, smooth and natural speech recognition.'
                }
            ]
        },
        choose: {
            h2: 'Why Choose Us?',
            h4: 'We provide cutting-edge technology, exceptional service value, and the most competitive pricing.',
            list: [
                {
                    icon: 'choose_icon_1.svg',
                    title: 'Speaker Diarization',
                    info: 'Automatically distinguish speakers in the audio for efficient analysis of multi-party conversations.'
                },
                {
                    icon: 'choose_icon_2.svg',
                    title: 'Faster Transcription',
                    info: 'Achieve rapid speech recognition with our efficient services.'
                },
                {
                    icon: 'choose_icon_3.svg',
                    title: 'Lower Cost',
                    info: 'Minimize your costs by using our services.'
                },
                {
                    icon: 'choose_icon_4.svg',
                    title: 'Multi-Language Support',
                    info: 'Embrace global communication with multi-language models and code-switching support.'
                },
                {
                    icon: 'choose_icon_5.svg',
                    title: 'Higher Accuracy',
                    info: 'Based on advanced speech recognition technology, achieve accurate speech transcription. '
                },
                {
                    icon: 'choose_icon_6.svg',
                    title: 'Flexible Deployment',
                    info: 'Choose from cloud API and on-prem deployment options to meet your needs.'
                }
            ]
        }
    },
    languages: {
        p1: {
            title: 'Code-Switching Speech Recognition',
            text: 'Seamless code-switching capabilities, smooth and natural speech recognition.',
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        support: {
            h2: 'Support Dozens of Languages',
            h4: 'Support multi-language speech recognition.',
            lists: [
                {
                    icon: 'languages_bg.svg',
                    title: 'Multiple Languages',
                    info: 'Accurate multilingual recognition, efficiently completing speech recognition tasks.'
                },
                {
                    icon: 'translation_bg.svg',
                    title: 'Translation',
                    info: 'Accurate transcription in multiple languages, intelligent translation without linguistic barriers.'
                },
                {
                    icon: 'subtitles_bg.svg',
                    title: 'Subtitles',
                    info: 'Rapid subtitle generation powered by our precise speech transcription.'
                },
                {
                    icon: 'code_bg.svg',
                    title: 'Code-Switching',
                    info: 'Seamless code-switching capabilities, smooth and natural speech recognition.'
                }
            ]
        }
    },
    translation: {
        p1: {
            title: 'Transcribe audio and video into text',
            text: 'Shatter Language Boundaries: AI-Powered Audio Translation and Transcription at Your Service.'
        },
        online: {
            title: 'Online Translation',
            info: 'Real-time translation without waiting. Our online translation service supports multiple languages and provides high-quality translation results anytime, anywhere. Simply upload audio or connect a microphone to get real-time translations, improving communication efficiency.'
        },
        punc: {
            title: 'Punctuation Prediction',
            info: 'Unstable network? No worries! Our offline translation feature allows you to translate anytime without relying on the network. Pre-download the required language packs to get fast and accurate translation results in an offline environment, ensuring barrier-free communication anytime, anywhere.'
        },
        languages: {
            h2: 'Supporting Multi-Language Translation',
            h4: 'We support transcription and translation capabilities in dozens of languages, ensuring the success of your business.'
        },
        exper: {
            title: 'Experience Speed, Precision, and Dependability in Translations',
            info: "When it comes to translations, accuracy and reliability are non-negotiable. With our cutting-edge AI and machine learning technologies, you're guaranteed top-notch quality. Our speech-recognition software effortlessly transcribes audio or video, freeing you from the tedious task of manual transcription. For impeccable accuracy, just tweak and fine-tune the text as needed."
        },
        ideal: {
            title: 'Ideal for YouTube, Podcasts, Interviews, and Business Meetings',
            info: "Our transcription service excels in converting diverse audio and video content—whether it's podcasts for Spotify, interviews, speeches, and beyond—into written form, or video creator for Youtube, Tiktok. Enhance your video content with captions to reach a broader audience. Plus, transcriptions pave the way for repurposing content into blogs and articles. Instant video translation also available."
        },
        customization: {
            title: 'Fully Tailored Translations, Customized to Meet Your Needs! ',
            info: "Our service provides flexible customization, ensuring your audio translations are perfectly aligned with your requirements. With the capability to translate media into over 20 languages—ranging from Chinese, Japanese, and Korean to Spanish, American English, and British English—we've got you covered. Additionally, transcribe audio to text and add subtitles to make your content universally accessible."
        }
    },
    proDevice: {
        p1: {
            title: 'On-Device Intelligent Speech Interaction Solution',
            text: 'Empower with advanced technology to achieve intelligent speech interaction with low resource consumption, multi-language support, and on-device computation, meeting the needs of edge computing scenarios while ensuring privacy and security.'
        },
        advantages: {
            h2: 'Advantages of On-Device Deployment',
            h4: 'Suitable for portable devices such as smartphones, translation devices, and dictionary pens, efficient speech interaction functions can be achieved without the internet connection.',
            list: [
                {
                    icon: 'advantage_1.svg',
                    title: 'Outstanding Performance',
                    info: 'Utilizing advanced model distillation algorithms, it ensures excellent performance even on low-computing power mobile devices.'
                },
                {
                    icon: 'advantage_2.svg',
                    title: 'Streamlined Optimization',
                    info: 'Employing efficient compression algorithms, the model is compressed to 10% of its original size, with the smallest possible size being 50MB, making it suitable for mobile devices.'
                },
                {
                    icon: 'advantage_3.svg',
                    title: 'Permanent License',
                    info: 'A single authorization for perpetual use, flexibly meeting the needs of various scenarios.'
                }
            ]
        },
        program: {
            h2: 'Solution Architecture',
            programImg: 'program_image.png',
            h4: 'Our on-device architecture solution is flexible and can adapt to a variety of complex requirements.'
        },
        offline: {
            title: 'Offline Capabilities',
            info: 'Integrate the SDK into mobile device applications to enable offline invocation to all capabilities. With just an initial online activation, you can use all features offline permanently, providing offline support for scenarios without network access, such as overseas travel. For customers with higher data security requirements, an offline activation option is available, ensuring absolute data security by not connecting to the internet at any point during the process.'
        },
        integration: {
            title: 'Easy Integration',
            info: 'Support mainstream platforms such as Android and iOS, offering comprehensive SDKs, detailed documentation and sample code for easy secondary development. It supports the simultaneous loading of multi-language models to avoid the time-consuming process of switching between them.'
        },
        config: 'Configuration Recommendations for Lower Costs',
        configImg: 'config_image.png'
    },
    proPrem: {
        p1: {
            title: 'On-Prem Deployment, Secure, Controllable and Customizable',
            text: 'On-prem deployment ensures data security, provides highly reliable services, and supports flexible customization to meet the special needs of enterprises.'
        },
        state: {
            h2: 'Current State of the Speech Interaction Industry',
            h4: 'Frequent technical challenges, unsatisfactory user experience.',
            list: [
                {
                    icon: 'state_1.svg',
                    text: 'In industries with high data security requirements, public cloud services cannot meet the demands.'
                },
                {
                    icon: 'state_2.svg',
                    text: 'Traditional solutions struggle to meet the needs of offline scenarios.'
                },
                {
                    icon: 'state_3.svg',
                    text: 'Limited language support range, difficult to cope with multilingual scenarios.'
                },
                {
                    icon: 'state_4.svg',
                    text: 'On-prem deployment is expensive and fails to meet customization requirements.'
                },
                {
                    icon: 'state_5.svg',
                    text: "When industry-specific terminology is involved, the general model's performance is not satisfactory."
                },
                {
                    icon: 'state_6.svg',
                    text: 'Deployment and access operations are complex, making it difficult for users to perform secondary development.'
                }
            ]
        },
        advantages: {
            h2: 'Advantages of Our On-Prem Deployment',
            h4: 'Easy and fast deployment, secure and reliable, meeting the needs of diverse scenarios, and promoting the digital development of enterprises.',
            data: {
                title: 'Ensure data security and control',
                info1: "Dolphin Voice's on-prem deployment solution ensures enterprise data security and maximizes user privacy protection.",
                info2: 'Our deployment supports both on-prem servers and public cloud platforms such as AWS EC2 and Azure VM, enabling flexible architecture design, optimized resource allocation, and enhanced system performance and scalability.'
            }
        },
        easy: {
            title: 'Easy deployment with low hardware configuration requirements',
            info1: 'Our solution offers simple and swift deployment, with low configuration requirements, supports a variety of platforms, and therefore reduces the deployment burden for enterprises.',
            info2: 'Deep Acceleration Optimization: Eliminating reliance on GPUs. The streamlined speech recognition model for a single language is approximately 2-3 GB in size, which requires less memory, and can be run on standard hardware configurations.'
        },
        sdk: {
            title: 'Rich SDKs for quick integration',
            info1: 'We provide rich SDK resources that support mainstream development languages, facilitating enterprises to quickly integrate them into their own applications.',
            info2: 'We offer SDKs for mainstream programming languages such as Python, Java, C++, and JavaScript to help developers build applications quickly.'
        },
        langs: {
            title: 'Multi-language support for global needs',
            info1: "Our speech recognition technology supports over 20 languages including Japanese, English, and Mandarin, suitable for various interaction scenarios.",
            info2: "Developers can quickly integrate speech recognition services to build a variety of speech interaction products, ensuring smooth interaction experiences worldwide."
        },
        flexible: {
            title: 'Customizable and scalable',
            info1: 'Our solution offers simple and swift deployment, with low configuration requirements, supports a variety of platforms, and therefore reduces the deployment burden for enterprises.',
            info2: 'We provide rich SDKs and APIs that support mainstream development languages, making it easy for users to perform secondary development.'
        },
        offline: {
            title: 'Private network invocation, designed for enterprise data security',
            info1: "Dolphin Voice's speech recognition technology supports offline invocation on private cloud, specifically designed for scenarios where data security is crucial. Our speech processing algorithms and pre-trained language models allow servers to process speech recognition tasks locally without the internet connection.",
            info2: 'This feature is particularly suitable for government and enterprise users handling data with high privacy protection requirements, such as legal documents, medical documentation, and meeting minutes. With our on-prem deployment services, these devices can be freed from network dependence, enjoy improved reliability and real-time performance, and thus deliver a smoother and more stable experience for users.'
        },
        support: {
            h2: 'Support Various Deployment Methods',
            h4: 'Dolphin Voice offers flexible technical architectures that support various deployment methods.',
            computing: {
                title: 'Flexible deployment for high-performance computing',
                computingImg: 'computing_image.png',
                info1: 'We support deployment on a single server (or computing clusters), fully utilizing existing performance. Users can choose the appropriate business modules and the number of instances according to their requirements.'
            }
        },
        lightweight: {
            lwImg: 'lightweight_image.png',
            title: 'Lightweight deployment for portable intelligent speech platforms',
            info1: 'We support deployment on devices such as laptops, desktops, and OPS hosts to build lightweight intelligent speech interaction platforms. This facilitates highly portable AI services while ensuring data security. Our system supports deployment on both Linux and Windows platforms. Users can activate or deactivate various feature modules based on their scenario needs and host performance, which helps to reduce service and operational costs, and improve computational efficiency.'
        },
        cost: {
            costImg: 'cost_image.png',
            title: 'Configuration recommendations for lower costs',
            info1: 'We provide a range of configuration recommendations suitable for different scenario needs, such as lightweight deployment and high-performance computing. We offer optimized configuration recommendations that correspond to various application requirements and business scales. Users can choose and adjust their configuration flexibly according to actual situations to realize efficient operation.'
        },
        pss: {
            h2: 'Application Scenarios',
            h4: 'High-precision speech recognition with a wide range of applications, fully meeting customer needs.',
            list: [
                {
                    icon: 'service_1.svg',
                    title: 'Voice Assistant',
                    info: 'Make typing-free operation possible in diverse scenarios like car navigation and smart speakers.'
                },
                {
                    icon: 'service_2.svg',
                    title: 'Audio/Video Transcription',
                    info: 'Rapidly convert audio/video files from meetings, interviews, etc. into transcripts<br> (or subtitles).'
                },
                {
                    icon: 'service_3.svg',
                    title: 'Intelligent Customer Service',
                    info: 'Rapid transcription of customer service calls, facilitating the recording and analysis of customer needs, enhancing the quality of service.'
                },
                {
                    icon: 'service_4.svg',
                    title: 'Audiobooks',
                    info: 'Allow machines to read literary works with emotive voices, relieving your eyes while treating your ears.'
                },
                {
                    icon: 'service_5.svg',
                    title: 'Real-Time Subtitles',
                    info: 'Transcribe audio from live speeches or livestreams into real-time subtitles, allowing for further processing such as translation.'
                },
                {
                    icon: 'service_6.svg',
                    title: 'Education and Training',
                    info: 'Provide reference texts for recorded lectures, webinars, and other events to improve search efficiency.'
                }
            ]
        },
        concat: {
            left: {
                text1: 'Apply for a Trial Now',
                text2: 'Submit your application and get the installation package for deployment and testing.'
            },
            tips: {
                errorEmail: 'Wrong e-mail format!',
                empty: 'Required Fields',
                selectTip: 'Please Select'
            },
            formBtn: 'Submit',
            form: [
                {
                    icon: 'email_icon.svg',
                    text: 'E-mail *'
                },
                {
                    icon: 'name_icon.svg',
                    text: 'Your Name *'
                },
                {
                    icon: 'phone_icon.svg',
                    text: 'Phone Number'
                },
                {
                    icon: 'job_icon.svg',
                    text: 'Job Title'
                },
                {
                    icon: 'organization_icon.svg',
                    text: 'Organization Name *'
                },
                {
                    icon: 'belong_icon.svg',
                    text: 'What industry do you belong to?'
                },
                {
                    icon: 'intended_icon.svg',
                    text: 'What is your intended use case? *'
                }
            ],
            belongList: [
                'Infomation & Communication / Internet',
                'Finance / Business Services',
                'Entertainment / Media',
                'Automobile / Machine',
                'Construction / Real Estate',
                'Transportation / Logistics',
                'Retail / Catering',
                'Government / Public Services',
                'Food Manufacturing / Agriculture',
                'Resources / Energy / Materials'
            ]
        },
        // activate: {
        //     h2: 'Activate Trial Service',
        //     h4: 'Submit your trial application and deploy the service to activate a 30-day unlimited full-feature experience.',
        //     btn: 'Activate',
        //     inputTip: 'Please enter your serial number',
        //     list: [
        //         {
        //             icon: 'resource_icon_1.svg',
        //             title: 'Downloads',
        //             info: 'Download the latest SDKs, sample code, and technical documentation to help you quickly develop speech interaction applications.',
        //             filePath: ''
        //         },
        //         {
        //             icon: 'resource_icon_2.svg',
        //             title: 'Documents',
        //             info: 'We provide detailed API reference manuals, integration guides, and best practices to fully support your development and integration work.',
        //             filePath: 'api/intro/notice.md'
        //         }
        //     ]
        // }
    },
    API: {
        p1: {
            title: 'Cost-Effective Open Cloud API Services',
            text: 'The Dolphin Voice platform offers high-quality AI cloud services, empowering businesses and individual users to quickly integrate intelligent speech interaction capabilities. With automatic billing based on monthly usage, customers incur no server maintenance costs.'
        },
        features: {
            h2: 'Product Features',
            h4: 'Leading technical advantages, powerful product experience, and top-tier service.',
            list: [
                {
                    icon: 'features_1.svg',
                    title: 'Rich Features',
                    info: 'Support intelligent speech interaction, meeting the diverse business needs of various scenarios.'
                },
                {
                    icon: 'features_2.svg',
                    title: 'Fast Response',
                    info: 'Instant return of results with a response time as quick as 0.1 seconds.'
                },
                {
                    icon: 'features_3.svg',
                    title: 'Easy Integration',
                    info: 'Comprehensive SDK and API documentation are provided to achieve simple and direct service integration.'
                },
                {
                    icon: 'features_4.svg',
                    title: 'High Reliability',
                    info: 'Based on the industry-leading cloud computing platform, we provide 24/7 services.'
                }
            ]
        },
        advantages: {
            h2: 'Advantages of the Cloud API Solution',
            h4: 'Integrate the most powerful features with minimal development, experience the industry-leading speech interaction technology.',
            list: [
                {
                    icon: 'advantage_1.svg',
                    title: 'Elastic Scaling',
                    text: 'Provide elastic computing, storage, and networking resources according to demand, flexibly scaling up or down without the need for substantial capital or human resources for hardware expansion or optimization.'
                },
                {
                    icon: 'advantage_2.svg',
                    title: 'Flexible Payment Options',
                    text: "Adopting a pay-as-you-go billing model, customers can flexibly choose and pay for services based on actual usage, and don't need to budget in advance or invest a large amount of capital."
                },
                {
                    icon: 'advantage_3.svg',
                    title: 'High Availability and Disaster Recovery',
                    text: 'Deployed across multiple regions, availability zones, and nodes, ensuring continuous business operations.'
                },
                {
                    icon: 'advantage_4.svg',
                    title: 'Security and Reliability',
                    text: 'With a professional security team and related technology, certified by the ISO 27001 Information Security Management System, ensure data reliability and security.'
                }
            ]
        },
        sdks: {
            h2: 'Supported SDKs',
            h4: 'Easy to integrate, simple to develop, and allow for rapid service integration.'
        }
    },
    contact: {
        leftText: 'Contact us.',
        contactForm: {
            emptyText: 'Required Fields',
            btn: 'Submit',
            name: {
                label: 'Your Name*',
                icon: 'name_icon.svg'
            },
            email: {
                label: 'E-mail*',
                icon: 'email_icon.svg'
            },
            tel: {
                label: 'Phone Number*',
                icon: 'phone_icon.svg'
            },
            company: {
                label: 'Organization Name*',
                icon: 'organization_icon.svg'
            },
            project: {
                label: 'Job Title',
                icon: 'belong_icon.svg'
            },
            note: 'Inquiry Items*'
        }
    },
    security: {
        p1: {
            title: 'Reliable Security System',
            text: "To ensure that customers can use DolphinVoice with confidence and assurance, we have established a comprehensive security system, including information security and encryption technology.<br/>We pledge that the ownership and control of your data belong solely to you. In pursuit of industry leadership, we have established a strict compliance audit system to ensure that our services are both efficient and in full compliance with legal and industry standards."
        },
        data: {
            h2: 'Enterprise-Level Data Security',
            list: [
                {
                    title: 'ISO 27001',
                    info: 'ISO 27001 is an international standard for the Information Security Management System (ISMS) developed jointly by the International Organization for Standardization (ISO) and the International Electrotechnical Commission (IEC).  It provides a framework for organizations to properly conduct information asset protection and risk management, demonstrating the protection of information\'s "confidentiality," "integrity," and "availability." ',
                    btn: 'Detail',
                    icon: 'ISO_blue.png'
                },
                {
                    title: 'GDPR',
                    info: 'The General Data Protection Regulation (GDPR) is a regulation that sets legal requirements for the handling of personal data of individuals within the European Economic Area (EEA). It aims to strengthen the protection of personal data and privacy.',
                    icon: 'gdpr.svg'
                },
                {
                    title: 'APPI',
                    info: 'The Act on the Protection of Personal Information (APPI) is a law designed to protect the personal information of Japanese citizens, with the purpose of safeguarding personal rights when enterprises are handling personal information such as customer data. The law stipulates that enterprises are obliged to obtain consent and record when providing personal information to third parties.',
                    icon: 'appi.svg'
                },
                {
                    title: 'SSL',
                    info: 'SSL (Secure Sockets Layer) is one of the protocols for encrypting data transmission and reception over IP networks such as the Internet. It encrypts data communication, preventing other devices on the network (such as relay devices) from eavesdropping on or tampering with the content of the communication.',
                    icon: 'ssl.svg'
                }
            ]
        },
        tech: {
            h2: 'Technical Measures',
            list: [
                {
                    title: 'Data Backup and Disaster Recovery',
                    info: 'Dolphin AI regularly performs data backups and securely stores backup data (such as on servers based on major Japanese cloud platforms) in the event of unforeseen circumstances. Additionally, Dolphin AI has established a disaster recovery plan that allows for a quick switch to backup systems or data centers in the event of a disaster.',
                    icon: 'tech_1.png'
                },
                {
                    title: 'Password Management Policy',
                    info: 'Dolphin AI employs password hashing to ensure that user passwords are neither transmitted nor stored. All data, including audio and textual types, in the data center are encrypted according to standard protocols. Additionally, user data is anonymized to prevent unauthorized access or misuse.',
                    icon: 'tech_2.png'
                },
                {
                    title: 'Encryption of Data Transfer',
                    info: 'Dolphin AI employs a secure protocol (HTTPS) to encrypt data transferred between the client and server. To safeguard against data theft and tampering, the data in transit is encrypted using a robust combination of symmetric and asymmetric key encryption techniques.',
                    icon: 'tech_3.png'
                },
                {
                    title: 'System Operation and Monitoring',
                    info: 'Dolphin AI has implemented a strict monitoring system that tracks the performance and status of data processing in real time. It monitors key indicators such as traffic, processing time, and error rates, and has established appropriate warning mechanisms. This allows for the swift detection and resolution of potential issues and anomalies.',
                    icon: 'tech_4.png'
                }
            ]
        },
        operate: {
            h2: 'Operational Measures',
            list: [
                {
                    title: 'Security Management',
                    info: 'Dolphin AI has implemented strict security measures, employing mechanisms such as identity verification, access control, and permission management to restrict data processing authority. It also monitors and audits data access and manipulation.',
                    icon: 'operate_1.png'
                },
                {
                    title: 'Security Auditing',
                    info: 'Dolphin AI has established a security audit mechanism to monitor and record the activities of the system and users, as well as access to and operations on confidential data. Additionally, a security monitoring system has been introduced to respond quickly to potential security threats and intrusions.',
                    icon: 'operate_2.png'
                },
                {
                    title: 'Testing and Verification',
                    info: 'Before releasing each version, Dolphin AI conducts rigorous testing and verification, including unit testing, integration testing, and system testing, to ensure the reliability and stability of the data processing system under all circumstances.',
                    icon: 'operate_3.png'
                },
                {
                    title: 'Security Training',
                    info: 'Dolphin AI provides regular security training within the company, educating employees on data security best practices, potential threat detection, and the importance of data protection. Additionally, users are encouraged to take proactive security measures, such as regularly changing passwords and avoiding logging in on shared devices.',
                    icon: 'operate_4.png'
                }
            ]
        }
    },
    isms: {
        h2: 'We have obtained the ISMS (ISO 27001) Certification!',
        text1: 'Dolphin AI, K.K. has obtained the Information Security Management System (ISMS) certification in accordance with the international standard "ISO/IEC 27001:2022" on June 6, 2024.',
        text2: 'ISO/IEC 27001 is an international standard for the Information Security Management System (ISMS). It provides a framework for organizations to balance the confidentiality, integrity, and availability of information, as well as the effective utilization of information.',
        text3: 'By obtaining this certification, we will further enhance information security, ensure the accurate and safe handling of information assets, and strive to achieve information security that aligns with our management strategies. Dolphin AI will continue to work hard to ensure that our customers can use our services with confidence and safety under strict security measures and management systems.',
        tableData: [
            {
                label: 'Registered Organization',
                text: 'Dolphin AI, K.K.'
            },
            {
                label: 'Applicable Standard',
                text: 'ISO/IEC 27001:2022'
            },
            {
                label: 'Initial Certification Date',
                text: 'June 6, 2024'
            },
            {
                label: 'Valid Until',
                text: 'June 5, 2027'
            },
            {
                label: 'Scope of Certification',
                text: 'Development and technical services of Voice AI SaaS platform',
            },
            {
                label: 'Certification Number',
                text: 'N.CN24-12512D'
            }
        ]
    },
    pricing: {
        cardsType: ['With Log Storage', 'Without Log Storage'],
        tableData: {
            line1: 'Free Trial Period',
            unit1: ' mins',
            line2: 'Unit price',
            unit2: ' JPY/s',
            line3: 'Unit price',
            unit3: ' JPY/hrs',
            unit4: ' min'
        },
        title: 'Additional Notes',
        tips: [
            '* Each language\'s speech recognition engine comes with an additional feature for customizing hot words, which can be used without any extra charge.',
            '* If you choose "With Log Storage", you are entitled to 600 minutes of free usage each month (including real-time transcription and batch audio/video file transcription), and the audio data collected will contribute to the R&D and quality improvement of our products and services;',
            '* If you opt for "Without Log Storage", neither the audio nor the recognition results will be stored on the server;',
            '* The displayed amount is tax-exclusive.'
        ]
    },
    law: {
        h2: 'Disclosure based on the Specified Commercial Transactions Act',
        tableData: [
            {
                label: 'Company',
                text: `<span>DolphinAI Co., Ltd.</span>`
            },
            {
                label: 'Address',
                text: `<span>170-0013 Hareza Tower 20F,<br/>1-18-1 Higashiikebukuro,<br/>Toshima-ku, Tokyo</span>`
            },
            {
                label: 'Phone',
                text: `<span>(+81) 03-6775-4523</span>`
            },
            {
                label: 'Email',
                text: `<span>voice.contact@dolphin-ai.jp</span>`
            },
            {
                label: 'COO',
                text: `<span>Masahiro Asakura</span>`
            },
            {
                label: 'Department',
                text: `<span>Speech Interaction PF Department</span>`
            },
            {
                label: 'Services Provided',
                text: `<span>DolphinVoice SaaS API</span>`
            },
            {
                label: 'Service Provision Conditions',
                text: `<span>Please refer to the <a id="myTosButton" style="color: rgba(24, 144, 255, 1);cursor: pointer;">Terms of Service</a> for details.</span>`
            },
            {
                label: 'Usage Price',
                text: `<span>For more details, please refer to the <a id="myPriceButton" style="color: rgba(24, 144, 255, 1);cursor: pointer;">DolphinVoice Service Page Information</a>.</span>`
            },
            {
                label: 'Additional Charges',
                text: `<span>Customers are not required to bear any additional fees (such as delivery fees, processing fees, etc.) beyond the service charge.</span>`
            },
            {
                label: 'Payment Methods',
                text: `<span>・Credit Card Payment (VISA, MasterCard, Diners Club, Discover, JCB, American Express).</span>
                        <span>・Bank Remittance/Transfer</span>
                        <span>※Pre-approval by the bill agency is required.</span>`
            },
            {
                label: 'Start Date of Usage',
                text: `<span>When you apply for an account on the DolphinVoice service website, we will issue you an APPID for your use.</span>`
            },
            {
                label: 'Billing Date and Payment Due Date',
                text: `<span><b style="font-weight: bold;">Credit Card Payment</b></span>
                        <span>The charges for the current month's usage will be posted to your credit card account by the 1st of the following month.</span>
                        <span><i style="font-size: 16px;">※The payment date varies by credit card issuer, so please contact your credit card company directly for details.</i></span>
                        <span><b style="font-weight: bold;">Bank Remittance/Transfer</b></span>
                        <span>Typically, the invoice for the current usage month will be issued within three working days of the following month.</span>
                        <span>For remittances, the payment deadline is the end of the following month. For transfers, payments are typically made on the 27th of the following month.</span>`
            },
            {
                label: 'About Termination',
                text: `<span>As this service operates on a usage-based pricing model, there are no charges or contractual obligations if you do not use it.</span>`
            },
        ]
    },
    errorTips: {
        lackPermission: 'Lack of recording permission',
        notFound: 'No recording device found',
        errorWarning: 'Unexpected error:',
        discarded: 'Encoding queue is blocked, one frame has been discarded.',
        stopInfo: ':Transmission has been stopped',
        wsWarning: 'Connection failed',
        copy: 'Copy successful'
    },
    features: {
        btn: 'More than 20 Languages',
        p1: {
            title: 'Enhance Your Product Experience with Advanced Intelligent Speech Technology',
            text: "Utilize Dolphin Voice's diverse AI speech products to improve the user experience of your products. Our products have powerful performance, comprehensive features, and offer a variety of deployment options.",
            h2: 'Unique Features to Meet Personalized Needs  '
        },
        mutiLanguage: {
            title: 'Multi-Language Support',
            info: 'Our speech recognition technology supports over 20 languages, including Japanese, English, and Mandarin Chinese. Users, no matter where they are, can enjoy a convenient and natural speech interaction experience. Our powerful multi-language models help your product meet the needs of users from different countries and regions, and extend your service to a global scale.'
        },
        codeSwitching: {
            title: 'Code-Switching',
            info: 'Explore seamless code switching and adaptive speech recognition. Our technology can easily achieve code-switching speech recognition, accurately capturing bilingual interactions, making it highly suitable for global business and education, effortlessly connecting the world.'
        },
        resourceSaving: {
            title: 'Resource Saving',
            resourceImg: 'resource_saving.svg',
            info: 'Our efficient algorithms are designed to achieve optimal performance on CPUs, without the need for expensive GPUs. This makes deployment more convenient and cost-effective, suitable for a wide range of applications while maintaining speed and precision. It is ideal for companies seeking to use advanced technology while managing costs.'
        },
        captioning: {
            title: 'Real-Time Subtitles',
            info: "Speech recognition technology can help provide clear, accurate, and real-time subtitles, meeting various needs in different scenarios. Whether it's meetings, seminars, or streaming content, our solutions ensure accessibility and enhance audience engagement."
        },
        characteristic: {
            title: 'Advanced Features',
            info: 'Our speech recognition system offers enhanced features to cater to a variety of application scenarios. This includes real-time transcription for live events, voice commands for smart devices, and personalized virtual assistants for customer service. Advanced features such as noise adaptation and dialect recognition ensure the speech recognition accuracy in various scenarios.'
        },
        fullLink: {
            title: 'Full-Link',
            info: "Dolphin Voice's full-link solution offers full coverage of speech interaction capabilities, ensuring high accuracy and usability across various applications, providing customers with a powerful one-stop technical solution. Our advanced system can help simplify your processes and enhance the functions."
        },
        offer: {
            h2: 'What Can We Offer You?',
            offerImg: 'Batch_Transcription.png',
            h4: 'Use the most advanced speech interaction technology to maximize value within the simplest workflows.',
            info1: {
                title: 'Real-Time Transcription',
                text: 'Perform instant and accurate transcription of real-time streaming audio, quickly and precisely transcribe your extended speeches, and completely revolutionize your communication with advanced speech recognition technology.'
            },
            info2: {
                title: 'Audio/Video Transcription',
                text: 'Effortlessly transcribe a large number of pre-recorded audio and video files, transforming audio content into structured textual information that facilitates subsequent retrieval and further processing.'
            }
        },
        values: {
            h2: 'Exceptional Performance',
            valuesImg: 'values.png',
            h4: 'Enhance your product experience as much as possible by using the powerful features of our products.'
        },
        needs: {
            h2: 'Meet Diverse Needs Through a Variety Of Solutions',
            h4: 'Cloud API, On-Device, and On-Prem solutions meet a wide range of customer needs. Choose the appropriate plan based on your actual scenario to achieve the best experience.',
            info1: {
                title: 'Cloud API Solutions: Fast, Secure, and Scalable ',
                text: "With our APIs and SDKs, you can quickly integrate our services into your own applications, gaining instant advantages such as access to the latest features, languages, and updates, ensuring that your solutions are always at the forefront of innovation. You don't need to worry about server resources, and only pay for the services you use."
            },
            info2: {
                title: 'On-Prem Solutions: Ensure Data Security',
                text: 'Deploy the AI engines to your own servers, enjoying advanced features while meeting strict security and compliance standards. The deployment is convenient, with low hardware requirements for devices and no GPU dependency, which fully saves costs for users. We provide highly concurrent and reliable solutions that can flexibly meet the needs of actual scenarios.'
            },
            info3: {
                title: 'On-Device Solutions: Make Full Use of the Computing Power of Mobile Devices',
                text: 'The mobile solution allows you to run our advanced speech recognition engine directly on the terminal device, enabling offline access to all capabilities. With just an initial online activation, you can use all features offline permanently, providing offline support for scenarios without network access, such as overseas travel. For customers with higher data security requirements, an offline activation option is available, ensuring absolute data security by not connecting to the internet at any point during the process.'
            }
        }
    },
    backend: {
        logout: 'Sign Out',
        telFormat: 'Please enter in the format of half-width hyphens and half-width numbers',
        login: {
            title1: 'Sign In',
            title2: 'Forgot Password?',
            title3: 'Password Reset',
            form1: {
                mail: 'Please enter your email address',
                pwd: 'Please enter your password'
            },
            form2: {
                label1: 'Please enter a new password',
                label2: 'Confirm Pwd',
                placeholder1: '8-16 characters long, including letters, numbers, special symbols',
                placeholder2: 'Reconfirm Pwd'
            },
            btn1: 'Sign In',
            btn2: 'Submit',
            btn3: 'Submit',
            btn4: 'Create Account', 
            btn5: 'Forgot Password?',
            tips: {
                sendTip: 'Please enter your registered email address.<br/>We will email you a URL that will allow you to reset your password.',
                pwdError: 'Please enter the correct email address and password',
                noAccount: 'The entered email is not registered, so the password reset email could not be sent.',
                formatErr: '8-16 characters long, including letters, numbers, special symbols',
                inconsistency: 'Passwords Do Not Match'
            }
        },
        register: {
            title1: "Create Account",
            title2: 'Please provide the required information.',
            title3: 'Check registration details. Click Next to continue if all is correct, or Back to make changes.',
            tips: {
                agree1: '.',
                agree2: 'Terms of Service',
                agree3: ' and ',
                agree4: 'Privacy Policy',
                agree5: 'Please read and agree to the ',
                account1: 'Already have an account? ',
                account2: 'Sign In',
                account3: ' now.',
                send: 'The registration confirmation email has been sent to ',
                list: [
                    'Please complete your registration by following the URL in the received email.',
                    '※ The link is valid for 24 hours. If it has expired, please apply for registration again.',
                    '※ If you have not received the email after a while, please check if the email address you entered is correct. If necessary, consider reapplying for registration.'
                ],
                success1: 'Registration Successful, Please ',
                success2: '.',
                error: 'The link has expired, please reapply.',
                agreeTip: 'Please read and click to accept the Terms of Service',
                emailTip: 'Please enter a valid email address'
            },
            swiper: [
                {
                    line1: '①<br/>Create Account',
                    line2: 'Sign up with your email address and verify your account through it.'
                },
                {
                    line1: '②<br/>Add Credit Card',
                    line2: 'Link a credit card to unlock access to our services.'
                },
                {
                    line1: '③<br/>API Integration',
                    line2: 'Integrate our API and enjoy 60 minutes of free usage each month.'
                }
            ],
            formLabel: [
                'Email:',
                'Password:',
                'Confirm Pwd:',
                'Last Name:',
                'First Name:',
                'Phone No. :',
                'Co.Name:',
                'Zip Code:',
                'Co.Addr. :',
                'Dept.Name:',
                'Position:',
            ],
            btn1: 'Sign Up',
            btn2: 'Submit',
            btn3: 'Back',
            btn4: 'Next',
            btn5: 'Sign In'
        },
        menu: [
            {
                name: 'Credit Card Binding',
                icon: 'card.svg',
                activeIcon: 'card_active.svg',
                path: '/backend/binding'
            },
            {
                name: 'Usage',
                icon: 'used.svg',
                activeIcon: 'used_active.svg',
                path: '/backend'
            },
            {
                name: 'Engine List',
                icon: 'engine.svg',
                activeIcon: 'engine_active.svg',
                path: '/backend/engine'
            },
            {
                name: 'Connection URL',
                icon: 'pro_info.svg',
                activeIcon: 'pro_info_active.svg',
                path: '/backend/informations'
            },
            // {
            //     name: 'エラー集計',
            //     icon: 'error.svg',
            //     activeIcon: 'error_active.svg',
            //     path: '/backend/'
            // },
            {
                name: 'Customized Word Lists',
                icon: 'term.svg',
                activeIcon: 'term_active.svg',
                path: '/backend/term'
            },
            {
                name: 'Billing Details',
                icon: 'cost.svg',
                activeIcon: 'cost_active.svg',
                path: '/backend/costdetails'
            },
            {
                name: 'User Center',
                icon: 'user.svg',
                activeIcon: 'user_active.svg',
                path: '/backend/usercenter'
            }
        ],
        binding: {
            title1: 'Please complete the ',
            title2: 'Credit Card Binding',
            title5: ' first.',
            title3: 'Credit Card Binding',
            title4: 'Credit Card to be Bound',
            text1: 'Please fill in the information for the credit card you wish to register.',
            text2: 'Would you like to save the following credit card information?',
            tips:{
                text1: 'Instructions: ',
                text2: 'To enjoy our services, a credit card registration is required;',
                text3: 'The credit card registration will be carried out on the [Stripe] page, and our platform will not record your card details;',
                text4: 'If you require a B2B transfer, please click ',
                text5: 'here',
                text6: ' to contact us.',
            },
            regist: {
                text1: '① Enter Information',
                text2: '② Confirm Registration Details',
                text3: '③ Complete',
                form: {
                    title1: 'Customer Information',
                    label1: 'Your Name',
                    title2: 'Register Your Credit Card',
                    label2: 'Card Number',
                    label3: 'Expiration Date',
                    label4: 'Cardholder Name',
                    label5: 'CVC',
                    label6: 'Credit Card Brands',
                    placeholder2: 'CardVerificationCode',
                    btn1: 'Save',
                    btn2: 'Re-enter',
                    btn3: 'Save'
                },
                result: {
                    text1: 'Credit Card Binding Successful.',
                    text2: 'An error occurred, credit card binding failed.',
                    btn1: 'Return to User Center',
                    btn2: 'Re-enter'
                }
            }
        },
        used: {
            h2: 'Monthly Usage Details',
            info1: 'Usage as of ',
            info2: '. The displayed price is tax-exclusive.',
            tabNames: ['Cloud API Capability'],
            monthTip: 'Usage Months',
            offlineMon: 'Billing Months',
            onLineTableTitles: ['Engine List', 'Unit Price', 'Total  Duration (seconds)', 'Billing Duration (seconds)', 'Amount (Tax-Exclusive, JPY)'],
            offLineTableTitles: ['Engine List', 'Unit Price', 'Total Quantity (units)', 'Billing Quantity (units)', 'Amount (Tax-Exclusive, JPY)'],
            billingMethod: [
                'Usage-Based Billing<br/>(Free for first ',
                ' minutes; ',
                ' JPY/second thereafter)',
                '分',
                'Device-Based Billing<br/>(Free up to ',
                ' units; Beyond: ',
                ' JPY/unit)',
                '台',
                'No Free Trial Period Offered, '
            ],
            saveType: [
                '(without Log Storage)',
                '(with Log Storage)',
            ]
        },
        engine: {
            h2: 'Engine List',
            tabNames: ['Cloud API Capability'],
            info: '* If you choose "with Log Storage", you are entitled to 600 minutes of free usage each month, and the audio data collected will contribute to the R&D and quality improvement of our products and services;<br/>* If you opt for "without Log Storage", neither the audio nor the recognition results will be stored on the server;<br/>* The displayed amount is tax-exclusive.',
            cardsType: ['With Log Storage', 'Without Log Storage'],
            btn: 'View',
            onlineInfo: {
                line1: 'Free Trial Period',
                line2: 'Unit Price',
                line3: 'Discount Rate',
                unit1: ' minutes',
                unit2: ' JPY/second',
                unit3: 'Not Offered'
            }
        },
        project: {
            h2: 'Connection URL',
            info: '* If you encounter any issues, such as connection failures or difficulties in obtaining recognition results, please refer to the ',
            questions: 'FAQ',
            punctuation: ' section.',
            title1: 'Common Projects',
            createBtn :'Create a New Project',
            tip: '* APPID and APPSecret are used for service connection, allowing the creation of multiple projects for service invocation of different products. The usage across these projects will be aggregated for display and billing purposes.',
            list: {
                name: 'Project Name',
                appId: 'APPID',
                appSecret: 'APPSecret',
                desc: 'Project Description'
            },
            title2: 'Connection URL Sorted by Engine',
            tableTitle: ['Engine', 'HTTP Speech Recognition API', 'WebSocket Speech Recognition API'],
            file: ['Request for Transcription (Extreme Speed Edition)', 'Retrieve Transcription Results ', 'Task Query', 'Request for Transcription (Standard Edition)'],
            tableData: [
                {
                    name: 'Short Speech Recognition',
                    httpUrl: 'https://api.voice.dolphin-ai.jp/v1/asr/api',
                    wsUrl: 'wss://api.voice.dolphin-ai.jp/v1/asr/ws'
                },
                {
                    name: 'Real-Time Speech Recognition',
                    httpUrl: '',
                    wsUrl: 'wss://api.voice.dolphin-ai.jp/v1/asr/ws'
                },
                {
                    name: 'Audio/Video Transcription',
                    httpUrl: 'https://api.voice.dolphin-ai.jp/v1/asrfile/upload/vip',
                    httpUrl4: 'https://api.voice.dolphin-ai.jp/v1/asrfile/upload',
                    httpUrl2: 'https://api.voice.dolphin-ai.jp/v1/asrfile/result',
                    httpUrl3: 'https://api.voice.dolphin-ai.jp/v1/asrfile/tasks',
                    wsUrl: ''
                }
            ],
            copyText: 'Click to Copy the Link',
            dialog: {
                confirmBtn: 'OK',
                cancelBtn: 'Cancel'
            },
            form: {
                name: 'Project Name:',
                desc: 'Project Description:',
                formTitle: 'Create a New Project'
            }
        },
        term: {
            h2: 'Customized Word Lists',
            info1: '* Only txt files under 3MB with UTF-8 encoding are accepted.<br/>* For specific format, refer to the ',
            info3: '* Only txt files under 30MB with UTF-8 encoding are accepted.<br/>* For specific format, refer to the ',
            info2: '',
            tabTitles: ['Hot Words', 'Sensitive Words', 'Forcefully Replaced Words', 'Modal Particles'],
            tip1: 'A Total Of ',
            tip2: ' Sets, With ',
            tip3: ' More To Add.',
            tip4: 'Note: You can enter up to 300 characters.',
            placeholder1: 'Please enter the name of the hot word dictionary',
            placeholder2: 'Please enter the name of the sensitive word dictionary',
            placeholder3: 'Please enter the name of the forcefully replaced word dictionary',
            placeholder4: 'Please enter the content',
            btn1: 'Import Hot Word Dictionary',
            btn2: 'View',
            btn3: 'Update',
            btn4: 'Download',
            btn5: 'Delete',
            btn6: 'Import Sensitive Word Dictionary',
            btn7: 'Import Forcefully Replaced Word Dictionary',
            btn8: 'Reset To Default',
            btn9: 'Apply Changes',
            tableTitles: [
                'Hot Word Dictionary Name',
                'ID',
                'Number Of Hot Words',
                'Update Time',
                'Operations',
                'Sensitive Word Dictionary Name',
                'Forcefully Replaced Word Dictionary Name',
            ],
            addDialog: {
                title: 'Import Hot Word Dictionary',
                label1: 'Import Type: ',
                label2: 'Import File: ',
                radioList: ['Hot Words', 'Document-Based Optimization'],
                uploadTip: 'Click to select a local file to upload',
                tip: 'Currently, only the extraction of names for people, places, and institutions is supported.'
            },
            fileTip1: 'Please upload a file',
            fileTip2: 'File Size Exceeds Limit',
            fileTip3: 'Only txt files are accepted',
            delTip1: 'Hot Word Dictionary Deleted',
            delTip2: 'Sensitive Word Dictionary Deleted',
            delTip3: 'Forcefully Replaced Word Dictionary Deleted',
            delTip4: 'Deletion Canceled',
            updateTip: 'Update Finished',
            uploadSuccess: 'Upload Finished',
            updateDialog: {
                title: 'Update Hot Word Dictionary'
            },
            delInfo: {
                title: 'Prompt',
                text1: 'Are you sure you want to delete this hot word dictionary?',
                text2: 'Are you sure you want to delete this sensitive word dictionary?',
                text3: 'Are you sure you want to delete this forcefully replaced word dictionary?',
                confirmBtn: 'OK',
                cancelBtn: 'Cancel'
            },
            template1: 'Hot Word Dictionary Template.txt',
            template2: 'Sensitive Word Dictionary Template.txt',
            template3: 'Forcefully Replaced Word Dictionary Template.txt',
            detailTitle: ['Original Words', 'Target Words']
        },
        cost: {
            h2: 'Billing Details',
            info: '* You can view the billing details of each engine.<br/>* You will be billed 50 yen for any monthly consumption below 50 yen.',
            tabNames: [
                'Cloud API Capability',
                // '离线能力'
            ],
            No: 'Order Number: ',
            status:[
                'Payment Status: ',
                'Unpaid',
                'Paid',
                'Abnormal',
                'No Charge'
            ],
            monthTip: 'Billing Months',
            downloadText: 'Invoice',
            titles: ['Total Amount', 'Order Details'],
            overview: {
                title1: 'Total Amount (tax-inclusive)',
                title2: 'Subtotal',
                title3: 'Consumption Tax',
                unit: 'yen'
            },
            tableTitles: ['Engine Name', 'Fee Description', 'Amount (tax-exclusive)'],
            usedInfo: {
                text1: 'Pay as you go. (usage time: ',
                text2: ' seconds) <br/>[unit price: ',
                text3: ' yen/second] * [exceeded free package (',
                text4: ' minutes): ',
                text5: ' seconds]'
            },
            downloadInfo: {
                title: 'Download Creditor Invoice',
                text: 'Download your PDF credit invoice, which is billed under the company name you registered with. \nIf you don\'t mind, please click "Download".',
                downloadBtn: 'Download',
                cancelBtn: 'Cancel'
            }
        },
        user: {
            h2: 'Registration Details',
            info: '* The default value for the billing recipient is the corporate name.',
            listTitles:[
                'Email',
                'Password',
                'Last Name (Applicant)',
                'First Name (Applicant)',
                'Co.Name',
                'Dept.Name',
                'Position',
                'Phone No.',
                'Zip Code',
                'Co.Addr. ',
                'Bill Recipient',
                'Payment Methods',
                'Credit Card',
                'Payment Status',
            ],
            pay: [
                'Credit Card Payment',
                'B2B Transfer'
            ],
            status: "Current Status",
            statusList: ['Normal', 'Abnormal'],
            statusTip: '* Service is unavailable when the payment status is abnormal; please settle the relevant fees first.',
            payType: [
                'Cloud API Capability',
                'On-Device Capability'
            ],
            payTip: '* We accept VISA, MasterCard, Diners Club, Discover, JCB, and American Express.',
            btn: 'Change',
            pwdForm: {
                pwd: 'Current Password:',
                newPwd: 'New Password:',
                confirm: 'Confirm New Pwd:',
                formTitle: 'Change Password',
                tip: '* Upon password update, you will be redirected to the login page.'
            },
            infos: {
                formTitle: 'Change Registration Details',
                surname: 'Last Name (Applicant):',
                name: 'First Name (Applicant):',
                company: 'Co.Name:',
                department: 'Dept.Name:',
                jobTitle: 'Position:',
                tel: 'Phone No. :',
                zipCode: 'Zip Code:',
                addr: 'Co.Addr. :',
                invoiceRecipient: 'Bill Recipient:',
                tip: '* For corporate entities, please enter the legal status and company name without abbreviations.<br/>Abbreviation Example: Dolphin Voice. lnc.'
            }
        }
    }
}